import React, {useState} from 'react';
import { Trans } from 'react-i18next';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';

//import logoHorizontal from "../../images/logo-horizontal-bg-dark.png"

const InsertImageModal = props => {
	
	const [imageURL, setImageURL] = useState("");
  
	/*const handleSampleClick = (payload) => {
    	if (props.onImageConfirm)
    		props.onImageConfirm(payload) 
  	}*/
  	
  	const handleClick = () => {
    	if (props.onImageConfirm)
    		props.onImageConfirm({src: imageURL}) 
  	}
  
	return (
		<Modal
		    show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-insert-image-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-insert-image-modal">
  					{(props.title)
  						? props.title
  						: <Trans i18nKey="components.insert-image-modal.title">Insert Image Title</Trans>
  					}
  				</Modal.Title>
  			</Modal.Header>
  			<Modal.Body className="pt-0 pb-0">
  				<Container className="p-2">
  					{/*<Button
            			onClick={() =>
              				handleSampleClick({ altText: "Logo Horizontal", src: logoHorizontal})
            			}
          			>
            			Amostra
          			</Button>*/}
  					<Row className="mb-3">
						<FormGroup as={Col} controlId="formGridImageURL">
							<FormLabel><Trans i18nKey={"components.insert-image-modal.imageURL.label"}>Image URL</Trans></FormLabel>
							<FormControl type={"text"} name="imageURL" value={imageURL} onChange={(e) => { setImageURL(e.target.value)}} />
						</FormGroup>
					</Row>
  					
				</Container>
		  	</Modal.Body>
          	<Modal.Footer>			      		
          		<Button variant={props.variant} onClick={handleClick}> 
          			{(props.confirmText)
  						? props.confirmText
  						: <Trans i18nKey="components.insert-image-modal.confirm">OK</Trans>
  					}
          		</Button>
          		<Button variant="secondary" onClick={props.onHide}>
          			{(props.cancelText)
  						? props.cancelText
  						: <Trans i18nKey="components.insert-image-modal.cancel">Cancel</Trans>
  					}
				</Button>	
          	</Modal.Footer>
		</Modal>
	);
}

export default InsertImageModal;
