import React from 'react';
import { Link, useNavigate, useLocation, Navigate, useOutletContext  } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import Prompt from "../components/Prompt";
import UserPasswordFormControls from "../components/UserPasswordFormControls";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import UserPasswordConfig from "../config/UserPasswordConfig";

import { ValidationError } from "../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape(UserPasswordConfig.FormValidationRules);

const UpdateUserPassword = props => {
	
	let navigate = useNavigate();
	
	const location = useLocation();
	
	const { t } = useTranslation();
	
	const config = useConfig();
	
	let context = useOutletContext();
	
	if (!location.state || !location.state.item) {
		return (<Navigate to="/users" />)
	} 

	const updateUserPassword = (values) =>
		new Promise((resolve, reject) => {
			API.updateUserPassword(location.state.item.id, values, config.csrfToken).then(response => {
				resolve(response);
			}).catch(error => reject(error));
	});
      
	return (
		<Container >
			<Row className="mb-3">
        		<Col>
        			<h3>
        				<Trans i18nKey={"users.update-password.header"}>Update User Password</Trans> 
        			</h3>
        		</Col>
  
        	</Row>
        	<Row className="mb-3">
    			<Col>
    				<Formik
    					initialValues={{ newPassword:'', confirmedNewPassword:'',  forceChangePassword: true}}
    					validationSchema={validationSchema}
    					validateOnChange={false}
    					validateOnBlur={false}     
    					onSubmit={(values, actions) => {
    						//Clear Error/Success
    						context.onError(null);
    						context.onSuccess(null);
					
    						updateUserPassword(values)
    							.then((response) => {
    								//actions.resetForm({ values: values }) //Workaround to set dirty back to true
    								navigate("/users", {state: {success: t("users.update-password.success")}});
    							})
    							.catch(error => { 
    								
    								actions.setSubmitting(false);
    								
    								if (error instanceof ValidationError) {		
    									
    									log.info("Update User Password Attempt Failed: ", error.message);

    									if (error.detail) {            						
    	              						for (let key of Object.keys(error.detail)) {
    	              							let errorMsgs = error.detail[key];
    	              							errorMsgs.forEach((message) => {
    	              								actions.setFieldError(key, message);
    	              							});
    	              						}
    	              					}
    								} else {
    									log.error("Error Updating User Password: ", error.message);
    								}
    								
    								context.onError(error);
    								
    							})
					}}
		      	>
		      	{({isSubmitting, dirty, errors, values, handleChange, setFieldValue}) => (	
		      	
		      	<Form className="form-user" noValidate>
		      		<Prompt
		      			when={!!dirty && !isSubmitting}
		      			message={
							  JSON.stringify({
		      		           "title": t("form.unsaved-changes-modal.title"),
		      		           "bodyText": t("form.unsaved-changes-modal.body"),
		      		           "confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           "cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           "size": "lg",
		      		           "variant": "danger",
		      		          })
		      		   }
		      		/>

						<UserPasswordFormControls values={values} errors={errors} handleChange={handleChange} /> 
    					
	    			    <Row className="mt-3">    					   
    						<Col>
    							<Button variant="success" type="submit" disabled={isSubmitting} > 
    								{isSubmitting ? <Trans i18nKey="users.update-password.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faCheckCircle} /> <Trans i18nKey={"users.update-password.form.submit"}>Submit</Trans></span>} 
    							</Button>
    						    
    							<Button as={Link} variant="secondary" className="float-end" onClick={() => { navigate(-1)}} ><FontAwesomeIcon icon={faArrowCircleLeft} /> <Trans i18nKey="users.update-password.cancel">Cancel</Trans></Button>
    					   </Col>
    			       </Row>     
		      	</Form>	
		      )}
		      </Formik>
		      
		      </Col>
	        </Row>
	     </Container>
	);
}

export default UpdateUserPassword;
