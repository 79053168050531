import React from "react";

import '../styles/Switch.css';
import FormLabel from 'react-bootstrap/FormLabel';

const Switch = props => {

	return (
		<div>
			<label className="switch">
				<input type="checkbox"  
					name={props.name} 
					value={props.value} 
					checked={props.checked}
				    onChange={props.onChange}/>
			 	<span className="slider round"></span>
			</label>
			{props.label && <FormLabel className="switch-label align-text-bottom ms-2 mb-0"><span className="align-middle">{props.label}</span></FormLabel>}
		</div>
	);
};

export default Switch;