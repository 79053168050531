import React,  { Fragment, useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';

import FormControlErrors from "../components/FormControlErrors";

import CustomDraftJSEditor from "../components/CustomDraftJSEditor";
import CustomLexicalEditor from './CustomLexicalEditor';

const QuestionChoiceFormFields = props => {
	
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
			
		 return () => { isMounted = false };
		  
	}, []);
	

	const handleChoiceTextChange = (content) => {
        
        props.setFieldValue("choiceText", content);  
    }

   
	return(
		<Fragment>				
			<Row>
				<FormGroup as={Col} controlId="formGridChoiceText">
				    {/*<FormLabel><Trans i18nKey={props.i18nPrefix+"form.choiceText.label"}>Choice Text</Trans></FormLabel>*/}
				    <CustomLexicalEditor
  						onChange={handleChoiceTextChange}
  						content={props.values.choiceText}
  						isInvalid={!(props.errors.choiceText == null)}
					/>
				    <FormControlErrors block errors={props.errors.choiceText} />
				</FormGroup>
			</Row>
		</Fragment>
		
	) 
}


export default QuestionChoiceFormFields;
