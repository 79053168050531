import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

import Prompt from "../components/Prompt";
import UserPasswordChecklist from "../components/UserPasswordChecklist";

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import FormControlErrors from "../components/FormControlErrors";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import UserPasswordConfig from "../config/UserPasswordConfig";

import { ValidationError } from "../helpers/custom-errors";

import { compareJSON } from "../helpers/commons";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape({
	currentPassword: Yup.string()
	     .required(),
	...UserPasswordConfig.FormValidationRules,
});


const UpdateSelfPassword = props => {
	
	let navigate = useNavigate();
	
	const { t } = useTranslation();
	
	const config = useConfig();
	
	let context = useOutletContext();
	
	const initialValues = { currentPassword: '', newPassword:'', confirmedNewPassword:''};
	
	const updateSelfPassword = (values) =>
		new Promise((resolve, reject) => {
			API.updateSelfPassword(values, config.csrfToken).then(response => {
				resolve(response);
			}).catch(error => reject(error));
	});
      
	return (
		<Container >
			<Row className="mb-3">
        		<Col>
        			<h3>
        				<Trans i18nKey={"profile.change-password.header"}>Update User Password</Trans> 
        			</h3>
        		</Col>
  
        	</Row>
        	<Row className="mb-3">
    			<Col>
    				<Formik
    					initialValues={initialValues}
    					validationSchema={validationSchema}
    					validateOnChange={false}
    					validateOnBlur={false}     
    					onSubmit={(values, actions) => {
    						//Clear Error/Success
    						context.onError(null);
    						context.onSuccess(null);
							
    						updateSelfPassword(values)
    							.then((response) => {
    								//actions.resetForm({ values : values }) //Workaround to set dirty back to true
									context.onSuccess(response.success)
    								//navigate((location.state && location.state.from) ? location.state.from : "/",  { state:  { success: t("users.update-password.success")}});
    							})
    							.catch(error => { 
    								
    								actions.setSubmitting(false);
    								
    								if (error instanceof ValidationError) {		
    									
    									log.info("Update Self Password Attempt Failed: ", error.message);

    									if (error.detail) {            						
    	              						for (let key of Object.keys(error.detail)) {
    	              							let errorMsgs = error.detail[key];
    	              							errorMsgs.forEach((message) => {
    	              								actions.setFieldError(key, message);
    	              							});
    	              						}
    	              					}
    								} else {
    									log.error("Error Updating Self Password: ", error.message);
    								}
    								
    								context.onError(error);
    								
    							})
					}}
		      	>
		      	{({isSubmitting, dirty, errors, values, handleChange, setFieldValue}) => (	
		      	
		      	<Form className="form-user-password" noValidate>
		      		<Prompt
		      			when={!!dirty && !isSubmitting}
		      			message={
		      		      JSON.stringify({
		      		           "title": t("form.unsaved-changes-modal.title"),
		      		           "bodyText": t("form.unsaved-changes-modal.body"),
		      		           "confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           "cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           "size": "lg",
		      		           "variant": "danger",
		      		           "when": compareJSON(initialValues, values)
		      		          }
		      		      )
		      		   }
		      		/>
					
					<Row>
						<Col md={4}>
						<Row className="mb-3">
    						<FormGroup as={Col} controlId="formGridCurrentPassword">
    							<FormLabel><Trans i18nKey="profile.change-password.form.currentPassword.label">Current Password</Trans></FormLabel>
    							<FormControl type={'password'} name="currentPassword" isInvalid={!(errors.currentPassword == null)} value={values.currentPassword} onChange={handleChange} placeholder={t('profile.change-password.form.currentPassword.placeholder')} />
    							<FormControlErrors errors={errors.currentPassword} />
    						</FormGroup>
						</Row>
    					<Row className="mb-3">
    						<FormGroup as={Col} controlId="formGridNewPassword">
    							<FormLabel><Trans i18nKey="profile.change-password.form.newPassword.label">New Password</Trans></FormLabel>
    							<FormControl type={'password'} name="newPassword" isInvalid={!(errors.newPassword == null)} value={values.newPassword} onChange={handleChange} placeholder={t('profile.change-password.form.newPassword.placeholder')} />
    							<FormControlErrors errors={errors.newPassword} />
    						</FormGroup>
						</Row>
						<Row className="mb-3">
    						<FormGroup as={Col} controlId="formGridConfirmedNewPassword">
    							<FormLabel><Trans i18nKey="profile.change-password.form.confirmedNewPassword.label">Confirmed New Password</Trans></FormLabel>
    							<FormControl type={'password'} name="confirmedNewPassword" isInvalid={!(errors.confirmedNewPassword == null)} value={values.confirmedNewPassword} onChange={handleChange} placeholder={t('profile.change-password.form.confirmedNewPassword.placeholder')} />
    							<FormControlErrors errors={errors.confirmedNewPassword} />
    							</FormGroup>
        				</Row>

						</Col>				
						
						<Col md={{ offset: 1 }}>
							<UserPasswordChecklist className="d-none d-lg-block d-md-block" value={values.newPassword} valueAgain={values.confirmedNewPassword}/>
						</Col>
						
						</Row>
    					
	    			    <Row className="mt-3">    					   
    						<Col>
    							<Button variant="success" type="submit" disabled={isSubmitting}> 
    								{isSubmitting ? <Trans i18nKey="profile.change-password.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faCheckCircle} /> <Trans i18nKey={"profile.change-password.form.submit"}>Submit</Trans></span>} 
    							</Button>
    						    
    							<Button variant="secondary" className="float-end" onClick={() => { navigate(-1)}}><FontAwesomeIcon icon={faArrowCircleLeft} /> <Trans i18nKey="profile.change-password.back">Back</Trans></Button>
    					   </Col>
    			       </Row>     
		      	</Form>	
		      )}
		      </Formik>
		      
		      </Col>
	        </Row>
	     </Container>
	);
}

export default UpdateSelfPassword;
