import { useLocation } from 'react-router-dom';
import { intersection } from 'lodash';
import moment from "moment";
//import log from 'loglevel';

export const useQuery = () => { 
	  return new URLSearchParams(useLocation().search);
};

export const isArrayWithLength = (arr) => {
 return (Array.isArray(arr) && arr.length)
}

export const isAllowedRoute = (route, user) => {
 if (!isArrayWithLength(route.roles) && !isArrayWithLength(route.permissions)) return true;
 else return intersection(route.roles, user.roles).length + intersection(route.permissions, user.permissions).length;
}

export const getAllowedRoutes = (routes, user) => {
 return routes.filter(({ roles, permissions }) => {
		 if(!isArrayWithLength(roles) && !isArrayWithLength(permissions)) return true;
		 else return intersection(roles, user.roles).length + intersection(permissions, user.permissions).length;
 });
}

export const getHomeBrowserRoute = (routes, user) => {
	return getHomeHashRoute(routes, user);
}

export const getHomeHashRoute = (routes, user) => {
	
	let homeRoutes = routes.filter(({ home }) => {
		if (!home) return false;
		else if(!isArrayWithLength(home)) return false;
		else return intersection(home, user.roles).length;
	});
	
	if (isArrayWithLength(homeRoutes))
		 return homeRoutes[0].path
	 
	return "/home";
}


export const getAllowedNavbarOptions = (options, user) => {
	 
	 return options.filter(({ roles, permissions }) => {
		 if(!isArrayWithLength(roles) && !isArrayWithLength(permissions)) return true;
		 else return intersection(roles, user.roles).length + intersection(permissions, user.permissions).length;
	 });
}


export const loadScript = (id, url, callback) => {
	  
	  const existingScript = document.getElementById(id);
	  
	  if (!existingScript) {
	    const script = document.createElement('script');
	    script.src = url;
	    script.id = id;
	    
	    document.body.appendChild(script);
	    //document.getElementsByTagName("head")[0].appendChild(script);
	    
	    script.onload = () => { 
	      if (callback) callback();
	    };
	    
	    script.onerror = () =>{ /* TODO: handle error */ }
	    
	  } else {
		  if (callback) callback();
	  }

};

export const promiseWithTimeout = (promise, milliseconds) => {

    const timeout =  new Promise((resolve, reject) =>
        setTimeout(() => 
            reject(`Promise timeout reached (limit: ${milliseconds} ms)`), 
            	milliseconds));

    return Promise.race([
        timeout, 
        promise
    ]);
};


export const parseInputDate = (inputDate) => {
    
    	let resultDate;
		
		if (inputDate) {
    		if (moment.isMoment(inputDate) || inputDate instanceof Date) {
				resultDate = moment.utc(inputDate);
			} else {	
				
				//resultDate = moment.utc(inputDate, 'DD/MM/YYYY');
				resultDate = moment.utc(inputDate, 'L');
				
				//If invalid set a custom flag: inputDate
				if (!resultDate.isValid())
					resultDate = moment.invalid({inputDate: inputDate});
			}
    		
    	}
 
    	return resultDate;
    }

 
 export const compareJSON = (obj1, obj2) => {

  var ret = {};
  for(var i in obj2) {
    if(!obj1.hasOwnProperty(i) || obj2[i] !== obj1[i]) {
      ret[i] = obj2[i];
    }
  }
  return ret;
}

export const niceBytes = (x) => {
 const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0, n = parseInt(x, 10) || 0;
  while(n >= 1024 && ++l){
      n = n/1024;
  }
  return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export const isAsyncSupported = () => {
	let isAsync = true;

	try {
  		eval('async () => {}');
	} catch (e) {
  		if (e instanceof SyntaxError)
    		isAsync = false;
  		else
    		throw e; // throws CSP error
	}
	
	return isAsync;
}

export const isObject = (val) => {
  if (val === null) { return false;}
    return ( (typeof val === 'function') || (typeof val === 'object') );
}

export const Regex = {
	DOUBLE: '^[0-9]{0,}(\\.[0-9]{1,})?$',
	CURRENCY: '^[0-9]{1,8}(\\.[0-9]{0,2})?$',
	NEGATIVE_CURRENCY: '^-?[0-9]{1,8}(\\.[0-9]{0,2})?$',
	CPF: '^\\d{3}\\x2E\\d{3}\\x2E\\d{3}\\x2D\\d{2}$',
	CNPJ: '^\\d{2}\\x2E\\d{3}\\x2E\\d{3}\\x2f\\d{4}\\x2D\\d{2}$',
	MOBILE_PHONE: '^\\+[1-9]\\d{1,14}$'
}


export const currencySymbol = (locale, currency) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });

  let symbol;
  formatter.formatToParts(0).forEach(({ type, value }) => {
    if (type === 'currency') {
      symbol = value;
    }
  });

  return symbol;
};

export const formatCurrency = (value, locale, currency) => {
  
  const formatter = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  });

  return formatter.format(value);
};

export const formatNumber = (value, locale, options) => {
  const formatter = Intl.NumberFormat(locale, options);

  return formatter.format(value);
};

export const formatPercent = (value, locale) => {
  
  const formatter = Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: 2
  });

  return formatter.format(value);
};


export const getAllFiles = (r) => {
 		let files = {};
  		r.keys().forEach((item, index) => { files[item.replace('./', '')] = r(item); });
 		return files
}

export const getNormalizedString = (str) => {
		return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
	};
	
export const CAN_USE_DOM =
  typeof window !== "undefined" &&
  typeof window.document !== "undefined" &&
  typeof window.document.createElement !== "undefined"
