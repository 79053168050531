import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { isArrayWithLength } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

import { useAuth } from "../services/use-auth";
import API from "../services/backend-api";

import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../styles/Typeahead.css";

//import moment from "moment";

const QuestionSourceInputSearch = props => {

	const [isLoading, setIsLoading] = useState(true);
	const [isSearching, setIsSearching] = useState(false);
	const [options, setOptions] = useState([]);
	const [selected, setSelected] = useState([]);
	const [pagedQuestionSources, setPagedQuestionSources] = useState(null);
	
	const pageSize = (props.pageSize) ? props.pageSize : 10;
	
	const { t } = useTranslation();
	
	const auth = useAuth();
	
	useEffect(() => {
		
		let isMounted = true; 
		
		if (props.sourceId) {
			
			//Get Question Source 
			API.lookupQuestionSource(props.sourceId)
				.then(response => {	
					
					let option = buildOptionItem(response);
					
					setOptions(new Array(1).fill(option));
					setSelected(new Array(1).fill(option));
					
					if (props.onLoad) props.onLoad(option);
								
			})
			.catch(error => { 
				log.error("Error Loading Question Source: ", error.message);
		 		props.onError(error);
			})
			.finally(() => {
				if (isMounted) setIsLoading(false);
			});
		 							
		} else {
			if (isMounted) { 
				setIsLoading(false);
				//setDefaultSelected([]);
				setSelected([]);
				setOptions([]);
			}
		}
		
		return () => { isMounted = false };
		  
	}, [props.sourceId]);
	
  	const handleQuestionSourcePaginate = (e, number) => {
	
		const pageIndex = Math.ceil(number/pageSize) - 1;
	    
		pageQuestionSources({filter: pagedQuestionSources.filter, pageSize: pageSize, pageIndex: pageIndex})
	      .then(({ list }) => {
		
			  let updatedOptions = options;
			  
			  list.forEach((item, index) => {
			  
			  	updatedOptions[pageSize*pageIndex + index] = buildOptionItem(item)
		
	    	  })
		 	 
	    	  setOptions(updatedOptions);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Paginate Question Sources Attempt Failed: ", error.message);
          						
				 props.onError(error.message);		
          						
          	 } else {
          		log.error("Paginate Question Sources Error: ", error.message);
          		props.onError(t('error.api.general'));
          	 }
		 })
		 
	}
	
	const buildOptionItem = (item) => {			  					
		return Object.assign({displayName: item.name}, item)
		
	}
	
	const handleQuestionSourceSearch = (query) => {
		
		setIsSearching(true);
	    
	    pageQuestionSources({filter: query, pageSize: pageSize})
	      .then(({ totalCount, list }) => {
				
			  const options = Array(totalCount).fill().map((e,index)=> {
			  		if (list[index]) {
						return buildOptionItem(list[index])
	    	  		}
			  		else
			   			return ({displayName: t(props.i18nPrefix+'question-source-input-search.loading')}) 
			  });  	 
		
	    	  setOptions(options);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Find Question Sources Attempt Failed: ", error.message);
         
				 props.onError(new Error(t(error.message)))		
          						
          	 } else {
          		log.error("Find QuestionSources Error: ", error.message);
				props.onError(new Error( t('error.api.general')))
          	 }
		 })
		 .finally(() => {
			 setIsSearching(false);
		 });
	  }
	  
	  const pageQuestionSources = (values) => {
		
		setIsSearching(true);
		
		return new Promise((resolve, reject) => {
			
			let filterActiveOnly =  (props.filterActiveOnly) ? {active: true} : {};
			
			API.pageQuestionSources(Object.assign({}, values, filterActiveOnly))
			.then(response => {
				setPagedQuestionSources(response);
				resolve(response);
			}).catch(error => {			
				reject(error);
			}).finally(() => {
			 setIsSearching(false);
		 	});
		});
		
	}
	  
	const handleQuestionSourceSelect = (items) => {
		
		if(isArrayWithLength(items)) { 
			setSelected(items);
			if (props.multiple) props.onChange(items);
			else props.onChange(items[0]);
		} else { 
			setSelected([]);
			if (props.multiple) props.onChange([]);
			else props.onChange(null);
		}
  	};

	const handleAllowNewQuestionSource = (items, control) => {
		
		return (props.onNewSource && !isArrayWithLength(items) && control.text.length >= 6);
  	};
						
	const renderMenuItemChildren = (option, properties) => { 
	
		return (
			<Fragment>
          		<Highlighter search={properties.text}>
            		{option.displayName}
         		</Highlighter>,
          		<div>
            		<small>
              			<Trans i18nKey={props.i18nPrefix+'question-source-input-search.menu-item-details'} values={{item: option}}/>
            		</small>
          		</div>
        	</Fragment>
    	)
    }
 

	// Bypass client-side filtering by returning `true`. Results are already
	// filtered by the search endpoint, so no need to do it again.
	const filterBy = () => true;
	
	
	return (
    	<Fragment>
    		<Row><Col>
				<InputGroup>
					<InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
				{(isLoading) ? <FormControl disabled  />
					: <AsyncTypeahead
						multiple={props.multiple}
						clearButton
						filterBy={filterBy}
						id="filter"
						name="filter"
						isLoading={isSearching}
						disabled={props.disabled} 
						onPaginate={handleQuestionSourcePaginate}
        				paginate={true}
						labelKey={'displayName'}
						minLength={3}
						maxResults={pageSize}
						onSearch={handleQuestionSourceSearch}
						onChange={handleQuestionSourceSelect}
						isInvalid={props.isInvalid}
						//onInputChange={(text, e) =>{console.log(text);}}
						//defaultSelected={defaultSelected}
						selected={selected}
						options={options}
						placeholder={t(props.i18nPrefix+'question-source-input-search.placeholder')}
						emptyLabel={t(props.i18nPrefix+'question-source-input-search.no-matches-found')}
						searchText={t(props.i18nPrefix+'question-source-input-search.searching')}
						promptText={t(props.i18nPrefix+'question-source-input-search.prompt')}
						newSelectionPrefix={t(props.i18nPrefix+'question-source-input-search.new-selection')}
						paginationText={t(props.i18nPrefix+'question-source-input-search.paginate')}
						renderMenuItemChildren={(props.renderMenuItemChildren) ? props.renderMenuItemChildren : renderMenuItemChildren}
						allowNew={handleAllowNewQuestionSource}
						//autoFocus={(!props.sourceId)}
					/>
				}
				</InputGroup>
			</Col></Row>
	</Fragment>
	)
};

export default QuestionSourceInputSearch;