import React, {useState, useEffect} from "react";
import { useOutletContext  } from 'react-router-dom';
import { Trans} from 'react-i18next';
import log from 'loglevel';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Loader from "../components/Loader";

import API from "../services/backend-api";


const About = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [buildInfo, setBuildInfo] = useState(null);
	const [environment, setEnvironment] = useState(null);
	
	let context = useOutletContext();
	
	useEffect(() => {
		let isMounted = true; 
		
				
		Promise.all([API.getEnvironment(),API.getBuildInfo()])
			.then((responses) => {
				setEnvironment(responses[0]);
				setBuildInfo(responses[1]);
			})
			.catch(error => { 
		 		log.error("Error Getting Enviroment and/or Build Info: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);
	
	if (isLoading) 
		return <Loader />
	
	if (!buildInfo||!environment) 
		return null;

	return (
		<Container>
			<h3><Trans i18nKey="about.environment">Environment</Trans></h3>
			{Object.keys(environment).map(key => {
				return (
					<Row key={key}>
						<Col md={2}>{key}:</Col>
						<Col md={10}>{environment[key]}</Col>
					</Row>
				)
			})}
			<br/>
			<h3><Trans i18nKey="about.buildInfo">Build Info</Trans></h3>
			{Object.keys(buildInfo).map(key => {
				return (
					<Row key={key}>
						<Col md={2}>{key}:</Col>
						<Col md={10}>{buildInfo[key]}</Col>
					</Row>
				)
			})}
		</Container>
	);
};

export default About;