import React from "react";
import {
  Navigate,
  useLocation,
  Outlet
} from "react-router-dom";
import { useAuth } from "../services/use-auth.js";
import log from 'loglevel';

import { getHomeBrowserRoute } from "../helpers/commons";
import { PrivateRoutesConfig } from '../config/PrivateRoutesConfig';

import Loader from "../components/Loader";


const isAuthorizedUser = (user, allowedRoles, allowedPermissions ) => {
	
	let _isGrantedByRole = false;
	let _isGrantedByPermission = false;
	
	 //CHECKING ROLES
	 //if props.roles is null authorization is granted
	 if (allowedRoles) {
		//if props.roles is NOT null, but user roles is null authorization is denied
		 if (user.roles) {
			 //loop into roles looking for a match
			 for(let i = 0; i < allowedRoles.length; i++){ 
				 if (user.roles.indexOf(allowedRoles[i]) !== -1)
					 _isGrantedByRole = true;
			 } 
		 } 
	 } else {
		 _isGrantedByRole = true;
	 }
	 
	//CHECKING PERMISSIONS
	 //if props.permissions is null authorization is granted
	 if (allowedPermissions) {
		//if props.permissions is NOT null, but user permissions is null authorization is denied
		 if (user.permissions) {
			 //loop into permissions looking for a match
			 for(let i = 0; i < allowedPermissions.length; i++){ 
				 if (user.permissions.indexOf(allowedPermissions[i]) !== -1)
					 _isGrantedByPermission = true;
			 } 
		 } 
	 } else {
		 _isGrantedByPermission = true;
	 }
	
	return _isGrantedByRole || _isGrantedByPermission ;
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export default function PrivateBrowserRoute({ children, ...rest }) {
  
	const auth = useAuth();
	let location = useLocation();
	  
	if (!auth.initialized)
		  return <Loader />
	  
    log.debug("Private Route: location = ", location.pathname);
	log.debug("Private Route: auth.user = ", auth.user);
	log.debug("Private Route: auth.status = ", auth.status);
	
	if (!auth.user) {
    	// Redirect them to the /login page, but save the current location they were
    	// trying to go to when they were redirected. This allows us to send them
    	// along to that page after they login, which is a nicer user experience
    	// than dropping them off on the home page.
    	return <Navigate to="/login" state={{ from: location }} replace />;
  	}
	
	if (auth.status === "CHANGE_PASSWORD") {
		// if status is CHANGE_PASSWORD user must be redirect as well
		return <Navigate to="/change-password" state={{ from: location }} replace />;
	}
	
	if (auth.status === "COMPLETED") {
		
		if (location.pathname === "/") {
			return <Navigate to={getHomeBrowserRoute(PrivateRoutesConfig, auth.user)} replace />;
		} else {
			return children ? children : <Outlet />;
			
			// check if route is restricted by role and/or permissions
    		/*if (isAuthorizedUser(auth.user, roles, permissions)) {
    			// Yeah!!! User is authorized to proceed
        		// return children or <Outlet>;
				return children ? children : <Outlet />;
    		} else{	
    			return <div><Trans i18nKey={"error.insuficient-permission"}>Insuficient Permission to Access Resource</Trans></div>
    		}  */   
			
		}
	}

          	
}