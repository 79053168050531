import React, {useState, Fragment} from "react";
import { useNavigate, useLocation, Outlet} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
//import rdd from 'react-device-detect';

import Breadcrumb from 'react-bootstrap/Breadcrumb'

import CustomContainer from "../components/CustomContainer";

import Header from "../components/Header";
import DismissableFeedback from "../components/DismissableFeedback";

import { useAuth } from "../services/use-auth";
import { PrivateRoutesConfig } from '../config/PrivateRoutesConfig';
import { isArrayWithLength, isAllowedRoute } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

const MainPage = props => {
	
 const [_error, _setError] = useState(null);
 const [_success, _setSuccess] = useState(null);
 const [_warning, _setWarning] = useState(null);
 const [_info, _setInfo] = useState(null);
 
 const auth = useAuth();
 const { t } = useTranslation();
 const location = useLocation();
 
 let navigate = useNavigate();

 const onError = (error) => { 
	 if (!error) {
		 _setError(null);
		 return;
	 }
	 
	 if (error instanceof AuthError) {		
		 auth.onUnathorized(error);
	 } else if (error instanceof ValidationError) {		
		 _setError(error.message);
	 } else {
		_setError(t('error.api.general'));
	}
 };
 
 const onSuccess = (success) => {
	_setSuccess(success);
	
 };

const onWarning = (warning) => {
	_setWarning(warning);
 };

const onInfo = (info) => {
	_setInfo(info);
 };
 
 let routeFound = PrivateRoutesConfig.find(route => route.path === location.pathname);

  return (
	
	<Fragment>	
		<Header 
			isPrivate={true} 
			onError={onError}
			onSuccess={onSuccess}
			onWarning={onWarning}
			onInfo={onInfo}
		/>
     	
		<CustomContainer>
			<DismissableFeedback feedback={_error} onClose={() => onError(null)} type="danger" />
			<DismissableFeedback feedback={_success} onClose={() => onSuccess(null)} type="success" />
			<DismissableFeedback feedback={_warning} onClose={() => onWarning(null)} type="warning" />
			<DismissableFeedback feedback={_info} onClose={() => onInfo(null)} type="info" />
		</CustomContainer>
		
		<CustomContainer>
			 {(routeFound && isArrayWithLength(routeFound.hierarchy)) ?
				<Breadcrumb>
  					{ routeFound.hierarchy.map((item, index) => 
						<Breadcrumb.Item key={index} onClick={() => { navigate((item.path) ? item.path : "#")}} >
							<Trans i18nKey={item.title} >{item.title}</Trans>
						</Breadcrumb.Item>
					)}
					<Breadcrumb.Item active><Trans i18nKey={routeFound.title}>{routeFound.title}</Trans></Breadcrumb.Item>
			  	</Breadcrumb>
				:
				null
			 }	
		</CustomContainer>
		
		{(routeFound && isAllowedRoute(routeFound, auth.user)) ?
			<Outlet context={{
				onError: onError,
				onSuccess: onSuccess,
				onWarning: onWarning,
				onInfo: onInfo}}
			/>
			:
			<div><Trans i18nKey={"error.insuficient-permission"}>Insuficient Permission to Access Resource</Trans></div>
		}
		
	</Fragment>
	
  );
}

export default MainPage;
