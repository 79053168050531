import React,  { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, Route, Navigate, useOutletContext  } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle, faCheckCircle, faChevronRight, faChevronLeft, faEdit } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import QuestionFormFields from "../components/QuestionFormFields";
import SubjectActivities from "../components/SubjectActivities";
import Prompt from "../components/Prompt";

import Loader from "../components/Loader";
import CustomStepper from "../components/CustomStepper";
import Stepper from 'bs-stepper'

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";

import QuestionConfig from "../config/QuestionConfig";

import { ValidationError } from "../helpers/custom-errors";
import { compareJSON } from "../helpers/commons";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
});

const linearValidationRules = [
	QuestionConfig.GeneralFormValidationRules,
	QuestionConfig.QuestionTextFormValidationRules,
	QuestionConfig.ChoicesFormValidationRules,
	QuestionConfig.ResolutionFormValidationRules,
	QuestionConfig.ImagesFormValidationRules
]

const completeValidationRules = {
	...Object.assign({}, ...linearValidationRules)
}

const linearValidationSchema = linearValidationRules.map((rules) => Yup.object().shape(rules));

const completeValidationSchema = Yup.object().shape(completeValidationRules);

const saveQuestionSteps = [
	  {
        key: 'general',
        component: QuestionFormFields.GeneralSection,
      }, 
      {
        key: 'question',
        component: QuestionFormFields.QuestionSection,
      },
      {
        key: 'choices',
        component: QuestionFormFields.ChoicesSection,
      },
      {
        key: 'resolution',
        component: QuestionFormFields.ResolutionSection,
      },
      {
        key: 'images',
        component: QuestionFormFields.ImagesSection,
      }
    ];

const SaveQuestion = props => {

	const [currentStep, setCurrentStep] = useState(0);
	const [question, setQuestion] = useState(null);
	const [stepper, setStepper] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	
	const steps = saveQuestionSteps;
  	const isLastStep = (currentStep === (steps.length - 1));
	
	let navigate = useNavigate();
	
	const auth = useAuth();
	
	const config = useConfig();
	
	const location = useLocation();
	
	let context = useOutletContext();
	
	const { t } = useTranslation();
	
	const initializeStepper = () => {
		
		var stepperEl = document.querySelector("#question-stepper");
		
		if (stepperEl) {
			
			var stepperObj = new Stepper(stepperEl,
				{
					linear: false, //true
			    	animation: false
				}
			);
			
			setStepper(stepperObj);
		
			stepperEl.addEventListener('show.bs-stepper', function (event) {
  				// event.preventDefault() can be used to prevent the display of a step
  				setCurrentStep(event.detail.indexStep);
			})
		}
    }
	
	
	useEffect(() => {
		let isMounted = true; 
		
		/*if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		*/ 
			
		if (location.pathname === '/questions/update' && location.state && location.state.item) {
			API.lookupQuestion(location.state.item.id, auth.isAdmin())
			.then(response => {
					setQuestion(response);
			})
			.catch(error => { 
				log.error("Error Loading Question: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) {
		 			setIsLoading(false);
		 		}
			});
		} else {
			if (location.pathname === '/questions/clone' && location.state && location.state.item) {
				if (isMounted) {
					setQuestion(location.state.item);
				}
			}
			
			if (isMounted) {
	 			setIsLoading(false);
	 		}
		}
		 
		 return () => { isMounted = false };
		  
	}, []);
	
	
	if (location.pathname === '/questions/update') {
		if (!location.state.item) {
			return (<Navigate  to="/questions" />)
		} else if (isLoading) {
			return <Loader />
		} else if (!question) {
			return null;
		}
	} else if (location.pathname === '/questions/clone') {
		if (!location.state.item) {
			return (<Navigate  to="/questions" />)
		} else if (isLoading) {
			return <Loader />
		} else if (!question) {
			return null;
		}
	} else {
		if (isLoading) {
			return <Loader />
		}
	}
	
	let initialValues = {}, action, actionIcon, currentValidationSchema;
	
	let totalValidatationRules = Object.assign({}, completeValidationRules);
	
	let defaultValues = Yup.object().shape(totalValidatationRules).default();
	
	
	if (location.pathname === '/questions/update') {
		Object.keys(totalValidatationRules)
			.forEach((key) => {
				initialValues[key] = (question[key]) ? question[key] : defaultValues[key];
			});
		action = "update";
		actionIcon = faCheckCircle;
		currentValidationSchema = completeValidationSchema;
	} else if (location.pathname === '/questions/clone') {
		
		//Cloned values if any		
		Object.keys(totalValidatationRules)
		.forEach((key) => {
			//initialValues[key] = (location.state.item[key]) ? location.state.item[key] : ["samples", "photos"].includes(key) ? [] : "";
			initialValues[key] = (question[key]) ? question[key] : defaultValues[key]; 
		});
		action = "clone";
		actionIcon = faPlusCircle; //faClone
		currentValidationSchema = completeValidationSchema;
	} 
	else {
		initialValues = defaultValues;
		
		action = "create";
		actionIcon = faPlusCircle;
		//currentValidationSchema = linearValidationSchema[currentStep];
		currentValidationSchema = completeValidationSchema;
	}
	
	const saveQuestion = (values) =>
		new Promise((resolve, reject) => {
			if (location.pathname === '/questions/update') {
				API.updateQuestion(location.state.item.id, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createQuestion(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});

    const hasStepErrors = (indexStep, errors) => {	
		
		return (Object.keys(linearValidationRules[indexStep])
			.find((key) => !(errors[key] == null)))
    }
    
    const handleNextStep = (values) => {
		if (!isLastStep) {
			 setCurrentStep(currentStep + 1);
			 stepper.next();
		 }
    }
    
    const handleStepBack = () => {
		 setCurrentStep(currentStep - 1);
		 stepper.previous();
    }
    
    /*const handleMoveToStep = (step) => {
		 setCurrentStep(step);
		 stepper.to(step);
    }*/
  
	return (
		<Container>
		<Formik
    		initialValues={initialValues}
    		validationSchema={currentValidationSchema}
    		validateOnChange={false}
    		validateOnBlur={false}     
    		onSubmit={(values, actions) => {
    			//Clear Error/Success
    			context.onError(null);
    			context.onSuccess(null);
    										
    			saveQuestion(values)
    				.then((response) => {
    					actions.resetForm({ values }) //Workaround to set dirty back to true
    					navigate("/questions", {state: { success: t("questions.save.success")}});
    				})
    				.catch(error => { 
    								
    					actions.setSubmitting(false);
    								
    					if (error instanceof ValidationError) {		
    									
    						log.info("Save Question Attempt Failed: ", error.message);

    						if (error.detail) {            						
    	              			for (let key of Object.keys(error.detail)) {
    	              				let errorMsgs = error.detail[key];
    	              				errorMsgs.forEach((message) => {
    	              					actions.setFieldError(key, message);
    	              				});
    	              			}
    	              		}
    					} else {
    						log.error("Error Saving Question: ", error.message);
    					}
    								
    					context.onError(error);
    								
    				})

				}}
		      	>
		      	{({isSubmitting, dirty, errors, values, handleChange, setFieldValue, validateForm, setFieldError, validateField}) => (	
		      	
		      	<Fragment>
		    		<Row>
		            	<Col>
		            		<h3>
		            			<Trans i18nKey={"questions.save.header-"+action}>Save Question</Trans> 
		            		</h3>
		            	</Col>
		      
		            </Row>
		            <Row className="mb-3">
		        		<Col>
		        			<Form className="form-question" noValidate>
		        				<Prompt
		        					when={!!dirty && !isSubmitting}
		        					message={JSON.stringify({
		        							"title": t("form.unsaved-changes-modal.title"),
		        							"bodyText": t("form.unsaved-changes-modal.body"),
		        							"confirmText": t("form.unsaved-changes-modal.confirm"), 
		        							"cancelText": t("form.unsaved-changes-modal.cancel"),
		        							"size": "lg",
		        							"variant": "danger",
		        							
		        							"when": compareJSON(initialValues, values)
		        						})
		        					}
		        				/>
		      		
						<CustomStepper.Container id="question-stepper" onMounted={initializeStepper}>
						
							<CustomStepper.Header className="mb-1">
							
								{ steps.map((step, index) => {
									return (
		    							<Fragment key={index}>
											{(index > 0) && <CustomStepper.HeaderLine /> }
											<CustomStepper.HeaderItem 
												number={index+1}
												label={t("questions.save.steps."+step.key)}
												target={"#question-stepper-content-"+index}
												isInvalid={hasStepErrors(index, errors)}
											/>
										</Fragment>
	      							)
	      						})}
							
							</CustomStepper.Header>
						
						<Row className="align-items-center">
						<Col md="auto">
							<OverlayTrigger
      							overlay={
        							<Tooltip>
          								<Trans i18nKey="questions.save.form.submit-previous">Previous</Trans>
        							</Tooltip>
      							}
    						>
								<Button onClick={handleStepBack} variant="link" disabled={currentStep === 0}>
                      				<FontAwesomeIcon icon={faChevronLeft} size="3x" />
                    			</Button>
							</OverlayTrigger>
						</Col>
						
						<Col>
						
						<CustomStepper.Content className="p-0">
							{ steps.map((step, index) => {
								
								const { 
		    						component: Component, 
		    						...rest 
		    					} = step;
								
								return (
		    						<Fragment key={index}>	
										<CustomStepper.ContentItem 
											key={index}
											id={"question-stepper-content-"+index}
										>
											<Component 
												values={values} 
												errors={errors} 
												onChange={handleChange} 
												onError={context.onError}
												setFieldValue={setFieldValue}
												setFieldError={setFieldError}
												validateField={validateField}
												i18nPrefix="questions.save."
											/>
										</CustomStepper.ContentItem>
									</Fragment>
	      						)
    						})}
						
						</CustomStepper.Content>
						</Col>
						<Col md="auto">
							<OverlayTrigger
      							overlay={
        							<Tooltip>
          								<Trans i18nKey="questions.save.form.submit-next">Next</Trans>
        							</Tooltip>
      							}
    						>
							<Button onClick={() => { 
								handleNextStep(values);
								/*validateForm().then((e) => {
									if (!hasStepErrors(currentStep, e)) handleNextStep(values)
								});*/}}
								variant="link" className="float-end" disabled={isLastStep} >
                      				<FontAwesomeIcon icon={faChevronRight} size="3x" />
                    		</Button>
							</OverlayTrigger>
						</Col>
						</Row>
						
						</CustomStepper.Container>
						
					   <Row className="mt-3 mb-3">    					   
    						<Col>  
    							<Button variant="success" type="submit" disabled={isSubmitting} > 
    								{isSubmitting ? <Trans i18nKey="questions.save.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"questions.save.form.submit-"+action}>Submit</Trans></span>} 
    							</Button>
    							
    							<Button as={Link} variant="secondary" className="float-end" to={"/questions"}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="questions.save.cancel">Cancel</Trans></Button>
    					   </Col>
    			       </Row>   
    			    </Form>	
		      	 </Col>
			     </Row>
			  </Fragment>
		      )}
		 </Formik>
		 
		 {(location.pathname === '/questions/update') && (
		 	<SubjectActivities 
		 		subject="QUESTION" 
		 		subjectId={location.state.item.id} 
		 		onError={context.onError}
		 	/>
		 )}
		 </Container>
	);
}

export default SaveQuestion;
