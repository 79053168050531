import React, { Suspense} from 'react';
import {Routes, Route, createBrowserRouter, RouterProvider} from 'react-router-dom';

import MainPage from "./MainPage";
import NotFoundPage from "./NotFoundPage";
import LoginPage from "./LoginPage";
import ForgotPasswordPage from "./ForgotPasswordPage";
import ResetPasswordPage from "./ResetPasswordPage";
import ChangePasswordPage from "./ChangePasswordPage";

import PrivateBrowserRoute from "../components/PrivateBrowserRoute";
import Loader from "../components/Loader";
import ErrorBoundary from "../components/ErrorBoundary";

import { ProvideAuth} from "../services/use-auth";
import { ProvideConfig } from "../services/use-config";

import { PrivateRoutesConfig } from '../config/PrivateRoutesConfig';

const AppRoutes = props => {

	let privateRoutes = PrivateRoutesConfig.map((route, index) => {
		    const { 
		    	path, 
		    	component: Component, 
		    	title,
		    	roles,
		    	permissions,
				hierarchy,
		    	...rest 
		    } = route;
								
		    return (
				<Route key={index} path={path} element={<Component />} />
		    )
	})
	
	return (
		<Routes>
			<Route exact path="/login" element={<LoginPage/>} />
	     	<Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
	     	<Route exact path="/reset-password/:token" element={<ResetPasswordPage />}  />
			<Route exact path="/change-password" element={<ChangePasswordPage />} />
			
	     	<Route path="/" element={<PrivateBrowserRoute/>}>
				<Route element={<MainPage/>}>
					{privateRoutes}
				</Route>
			</Route>
	     	
	     	<Route path={"*"} element={<NotFoundPage />} />
	     </Routes>
	);
}

const router = createBrowserRouter([
  	{ path: "*", Component: AppRoutes },
]);

const Root = props => {
	
	return (
		<ProvideConfig>	
		  <ProvideAuth>	
	     	<RouterProvider router={router} />
		 </ProvideAuth>
	   </ProvideConfig>	
	  );
}

// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
  return (
    <Suspense fallback={<Loader />}>
    	<ErrorBoundary>
    		<Root />
    	</ErrorBoundary>
    </Suspense>
  );
}
