import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Switch from "../components/Switch";
import FormControlErrors from "../components/FormControlErrors";


const UserPasswordFormControls = props => {

	const { t } = useTranslation();
	
	return (
		<Fragment>
		<Row className="mb-3">
    		<FormGroup as={Col} controlId="formGridNewPassword">
    			<FormLabel><Trans i18nKey="users.save.form.newPassword.label">New Password</Trans> *</FormLabel>
    			<FormControl autoComplete="new-password" type={'password'} name="newPassword" isInvalid={!(props.errors.newPassword == null)} value={props.values.newPassword} onChange={props.handleChange} placeholder={t('users.save.form.newPassword.placeholder')}  />
    			<FormControlErrors errors={props.errors.newPassword} />
    		</FormGroup>
    		<FormGroup as={Col} controlId="formGridConfirmedNewPassword">
    			<FormLabel><Trans i18nKey="users.save.form.confirmedNewPassword.label">Confirmed New Password</Trans> *</FormLabel>
    			<FormControl autoComplete="new-password" type={'password'} name="confirmedNewPassword" isInvalid={!(props.errors.confirmedNewPassword == null)} value={props.values.confirmedNewPassword} onChange={props.handleChange} placeholder={t('users.save.form.confirmedNewPassword.placeholder')} />
    			<FormControlErrors errors={props.errors.confirmedNewPassword} />
    		</FormGroup>
        </Row>
        <Row className="mb-3">    					   
	     	<FormGroup as={Col} controlId="formGridForceChangePassword">
	     		<Switch
	     			label={t('users.save.form.forceChangePassword.label')}
	     			name="forceChangePassword"
	     			value={props.values.forceChangePassword}
	     			checked={props.values.forceChangePassword}
	     			onChange={props.handleChange}
	     		/>
	     	</FormGroup>
	    </Row>
	    </Fragment>
	)
};

export default UserPasswordFormControls;