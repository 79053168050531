import React, {useState, useEffect, Fragment} from "react";
import log from 'loglevel';

import {$getRoot, $getSelection, ParagraphNode,} from 'lexical';
import {$generateHtmlFromNodes, $generateNodesFromDOM  } from '@lexical/html';

import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';

import { HeadingNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";

import LoadInitialContent from "./lexical/LoadInitialContent";
import ToolbarPlugin from "./lexical/ToolbarPlugin";
import EquationsPlugin from './lexical/EquationsPlugin';
import ImagesPlugin from './lexical/ImagesPlugin';
import { EquationNode } from "./lexical/EquationNode";
import { ImageNode} from "./lexical/ImageNode";

import { CustomParagraphNode } from "./lexical/CustomParagraphNode";
import Theme from "./lexical/Theme";

//import { useConfig } from "../services/use-config";

import '../styles/CustomLexicalEditor.css';



const CustomLexicalEditor = props => {
	
	const onError = (error) => {
  		log.error(error);
	}

	const initialConfig = {
    	namespace: 'SimEduca',
    	//theme: Theme,
   	    onError,
   	    nodes: [
			HeadingNode,
    		ListNode,
    		ListItemNode,
    		EquationNode,
    		ImageNode
			/*CustomParagraphNode, {
				replace: ParagraphNode,
				with: (node) => {
			  		return new CustomParagraphNode();
				}
		  	}*/
	],
    };
	
	const handleEditorStateChange = (editorState, editor) => {
        
    	log.debug('editorState', JSON.stringify(editorState));
        
        editor.update(() => {
    		let contentHtml = $generateHtmlFromNodes(editor);
    		log.debug(contentHtml);
    		props.onChange(contentHtml)
  		});
        
    }

	return (
		<LexicalComposer initialConfig={initialConfig}>
      		<LoadInitialContent initialContent={props.content} />
      		<ToolbarPlugin />
      		<RichTextPlugin
            	contentEditable={
              		<ContentEditable
                		className="form-control"
                		aria-placeholder={props.placeholder}
              		/>
            	}
            	ErrorBoundary={LexicalErrorBoundary}
          	/>
          	<HistoryPlugin />
          	<ListPlugin />
          	<EquationsPlugin />
          	<ImagesPlugin />
          	<OnChangePlugin onChange={handleEditorStateChange} ignoreSelectionChange/>
     	</LexicalComposer>);
	};


export default CustomLexicalEditor;