import React, {Fragment, useState} from 'react';
import { Trans } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button'
import FormControlErrors from "../components/FormControlErrors";
import { isArrayWithLength } from "../helpers/commons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import ImportPreviewModal from "../components/ImportPreviewModal";
import ImportErrorsModal from "../components/ImportErrorsModal";


const ImportErrors = props => {
	const [importErrorsModalShow, setImportErrorsModalShow] = useState(false);	
	const [importPreviewModalShow, setImportPreviewModalShow] = useState(false);
	
	const handleImportErrorsModalHide = () => {
	  setImportErrorsModalShow(false);
    }

	const handleImportPreviewModalHide = () => {
	  setImportPreviewModalShow(false);
    }
	
	return (
		<Fragment>
		{ isArrayWithLength(props.errors) ?
			<Fragment>
				<ImportErrorsModal
					i18nPrefix={props.i18nPrefix} 
					show={importErrorsModalShow}
					onHide={handleImportErrorsModalHide}
					size="xl"
					errors={props.errors}
					data={props.data}
				/>
				<ImportPreviewModal
					i18nPrefix={props.i18nPrefix} 
					show={importPreviewModalShow}
					onHide={handleImportPreviewModalHide}
					size="xl"
					errors={props.errors}
					customDisplayColumns={props.customDisplayColumns}
					data={props.data}
				/>
				<Alert variant={"danger"} className="mt-2 mb-0">
					<Trans i18nKey={props.i18nPrefix+"form.data.validation.errors"} >Errors</Trans> 
					<Button className="ms-3" size="sm" variant="danger" onClick={() => { setImportErrorsModalShow(true); }}>
						<FontAwesomeIcon icon={faExclamationCircle} /> <Trans i18nKey={props.i18nPrefix+"form.data.validation.detail-button"} >More Detail</Trans>		
					</Button>
					<Button className="text-white ms-3" size="sm" variant="warning" onClick={() => { setImportPreviewModalShow(true);  }} >
						<FontAwesomeIcon icon={faEye} /> <Trans i18nKey={props.i18nPrefix+"form.data.validation.preview-button"} >Preview</Trans>		
					</Button>
				</Alert>
			</Fragment>
		  :
			<FormControlErrors block={true} errors={props.errors} />
		}
		</Fragment>
	)
}

const ImportValidationSuccess = props => {
	const [importPreviewModalShow, setImportPreviewModalShow] = useState(false);
	
	const handleImportPreviewModalHide = () => {
	  setImportPreviewModalShow(false);
    }
	
	return (
		<Fragment>
			<ImportPreviewModal
				i18nPrefix={props.i18nPrefix} 
				show={importPreviewModalShow}
				onHide={handleImportPreviewModalHide}
				size="xl"
				data={props.data}
				customDisplayColumns={props.customDisplayColumns}
			/>
			<Alert variant={"success"} className="mt-2 mb-0">
				<Trans i18nKey={props.i18nPrefix+"form.data.validation.success"} count={props.data.length}>Sucess</Trans> 
				<Button className="text-white ms-3" size="sm" variant="warning" onClick={() => { setImportPreviewModalShow(true);  }} >
					<FontAwesomeIcon icon={faEye} /> <Trans i18nKey={props.i18nPrefix+"form.data.validation.preview-button"} >Preview</Trans>		
				</Button>
			</Alert>

		</Fragment>
	)
}
			
const ImportValidationResults = props => {

	if (props.isSubmitting || ((!props.errors)&&(!isArrayWithLength(props.data)))) {
		return null
	}

	return (
		<Fragment>
		{ (props.errors) ? 
				<ImportErrors 
					i18nPrefix={props.i18nPrefix} 
					errors={props.errors} 
					data={props.data}
					customDisplayColumns={props.customDisplayColumns}
				/>
		 	:
				<ImportValidationSuccess 
					i18nPrefix={props.i18nPrefix} 
					data={props.data} 
					customDisplayColumns={props.customDisplayColumns} 
				/>
		}
		</Fragment>
	)
};

export default ImportValidationResults;