import React,  { Fragment, useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faClose, faEdit, faCheck} from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';
import FormSelect from 'react-bootstrap/FormSelect';

import FormControlErrors from "../components/FormControlErrors";
//import FormControlHelper from "../components/FormControlHelper";

import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";
import { useConfig } from "../services/use-config";

import QuestionConfig from "../config/QuestionConfig";

import QuestionSourceInputSearch from "../components/QuestionSourceInputSearch";

import MultipleImageUploader from "../components/MultipleImageUploader";
import CustomLexicalEditor from "../components/CustomLexicalEditor";
import CustomDraftJSEditor from "../components/CustomDraftJSEditor";

//import DataTable from '../components/DataTable';

import InputMask from "react-input-mask";

import SaveQuestionChoiceModal from "../components/SaveQuestionChoiceModal";

import ConfirmationDialogModal from "../components/ConfirmationDialogModal";

//import { isArrayWithLength } from "../helpers/commons";

//import { Typeahead } from 'react-bootstrap-typeahead';
//import 'react-bootstrap-typeahead/css/Typeahead.css';
//import "../styles/Typeahead.css";

import parse from 'html-react-parser';
//import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';

const GeneralSection = props => {

	const [subjects, setSubjects] = useState([]);
	const [subjectTopics, setSubjectTopics] = useState([]);
	//const [questionSources, setQuestionSources] = useState([]);
	
	const { t } = useTranslation();
	
	const auth = useAuth();
	
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		//Fetch Subjects
		API.findSubjects({})
			.then(response => {
				if (isMounted) setSubjects(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Subjects: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		//Get Subject Topics
				if (props.values.subjectId) {
					getSubjectTopics(props.values.subjectId)
				}
			});
		
		 return () => { isMounted = false };
		  
	}, []);
	
        
    const getSubjectTopics = (subjectId) => {
    	API.findSubjectTopics(subjectId, {})
			.then(response => {
					setSubjectTopics(response.list); 					
			})
			.catch(error => { 
				log.error("Error Loading Subject Topics: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }
	
	const handleSubjectChange = (e) => {
        
        props.setFieldValue("subjectId", e.target.value);
        
        if (!e.target.value) {
        	setSubjectTopics([]);
        	props.setFieldValue("topicId", "");
        	return;
        }
        
        //Get Subject Topics
        getSubjectTopics(e.target.value);
        
        //Reset topic
        props.setFieldValue("topicId", "");
    }
    
    const handleSourceChange = (source) => {
		
		if (!source) {
			props.setFieldValue("sourceId", "");
        	return;
        } 

		props.setFieldValue("sourceId", source.id);
    }
    
    const handleNewSource = (e) => {
		//TODO
    }
    
	return(
		<Fragment>
		<Row className="mb-3">
			<FormGroup as={Col} controlId="formGridSubject">
				<FormLabel><Trans i18nKey={props.i18nPrefix+"form.subjectId.label"}>Subject</Trans> *</FormLabel>
				<FormSelect name="subjectId" isInvalid={!(props.errors.subjectId == null)} value={props.values.subjectId} onChange={handleSubjectChange} >
		    			<option value="">{t(props.i18nPrefix+"form.subjectId.blank-option")}</option>
		    			{ subjects.map(item =>
		    					<option key={item.id} value={item.id+""}>{item.name}</option>
		    			)}
		    	</FormSelect>
				<FormControlErrors errors={props.errors.subjectId} />
			</FormGroup>
			<FormGroup as={Col} controlId="formGridTopic">
				<FormLabel><Trans i18nKey={props.i18nPrefix+"form.topicId.label"}>Topic</Trans> *</FormLabel>
				<FormSelect disabled={!(props.values.subjectId)} name="topicId" isInvalid={!(props.errors.topicId == null)} value={props.values.topicId} onChange={props.onChange} >
		    			<option value="">{t(props.i18nPrefix+"form.topicId.blank-option")}</option>
		    			{ subjectTopics.map(item =>
		    					<option key={item.id} value={item.id}>{item.name}</option>
		    			)}
		    	</FormSelect>
				<FormControlErrors errors={props.errors.topicId} />
			</FormGroup>
			
			<FormGroup as={Col} controlId="formGridType">
				<FormLabel><Trans i18nKey={props.i18nPrefix+"form.type.label"}>Type</Trans> *</FormLabel>
				<FormSelect as="select" name="type" isInvalid={!(props.errors.type == null)} value={props.values.type} onChange={props.onChange} >
		    		<option  value="">{t(props.i18nPrefix+"form.type.options.blank")}</option>
		    		{ QuestionConfig.Types.map(item =>
		    			<option key={item} className="text-uppercase" value={item}>{t(props.i18nPrefix+"form.type.options."+item)}</option>
		    		)}
		    	</FormSelect>
				<FormControlErrors errors={props.errors.type} />
			</FormGroup>

		</Row>

		<Row className="mb-3">
			
			<FormGroup as={Col} controlId="formGridGrade">
				<FormLabel><Trans i18nKey={props.i18nPrefix+"form.grade.label"}>Grade</Trans> *</FormLabel>
				<FormSelect name="grade" isInvalid={!(props.errors.grade == null)} value={props.values.grade} onChange={props.onChange} >
		    		<option  value="">{t(props.i18nPrefix+"form.grade.options.blank")}</option>
		    		{ QuestionConfig.Grades.map(item =>
		    			<option key={item} className="text-uppercase" value={item}>{t(props.i18nPrefix+"form.grade.options."+item)}</option>
		    		)}
		    	</FormSelect>
				<FormControlErrors errors={props.errors.grade} />
			</FormGroup>
			
			<FormGroup as={Col} controlId="formGridSource">
				<FormLabel><Trans i18nKey={props.i18nPrefix+"form.sourceId.label"}>Source</Trans> *</FormLabel>
		    	<QuestionSourceInputSearch 
				    i18nPrefix={props.i18nPrefix+"form."}
				    onError={props.onError}
				    isInvalid={!(props.errors.sourceId == null)}
					onChange={handleSourceChange}
					sourceId={props.values.sourceId}
					//onNewSource={handleNewSource}
				 />
				<FormControlErrors block errors={props.errors.sourceId} />
			</FormGroup>
			
			<FormGroup as={Col} md={4} controlId="formGridYear">
				<FormLabel><Trans i18nKey={props.i18nPrefix+"form.year.label"}>Year</Trans> *</FormLabel>
				<FormControl as={InputMask} mask='9999' type={'text'} name="year" isInvalid={!(props.errors.year == null)} value={props.values.year} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.year.placeholder")} />
				<FormControlErrors errors={props.errors.year} />
			</FormGroup>

		</Row>
		</Fragment>
	) 
}


const QuestionSection = props => {

	//const [editorState, setEditorState] = useState(EditorState.createEmpty());
	
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
		
		/*let blocksFromHtml = htmlToDraft(props.values.questionText);
		const { contentBlocks, entityMap } = blocksFromHtml;
		const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
		setEditorState(EditorState.createWithContent(contentState));*/
		
		return () => { isMounted = false };
		  
	}, []);
	
	const handleQuestionTextChange = (content) => {
        
        props.setFieldValue("questionText", content);  
    }
	
	return(
		<Fragment>
			<Row className="mb-3">
				<FormGroup as={Col} controlId="formGridQuestionText">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.questionText.label"}>Question Text</Trans>* </FormLabel>
					{/*<CustomDraftJSEditor
  						onChange={handleQuestionTextChange}
  						content={props.values.questionText}
  						isInvalid={!(props.errors.questionText == null)}
					/>
					<br/>*/}
					<CustomLexicalEditor
  						isInvalid={!(props.errors.questionText == null)}
  						onChange={handleQuestionTextChange}
  						content={props.values.questionText}
  						placeholder={props.i18nPrefix+"form.questionText.placeholder"}
					/>
					<FormControlErrors block errors={props.errors.questionText} />
				</FormGroup>
			</Row>
		</Fragment>
	) 
}

const QuestionChoiceList = props => {
	
	const handleDoubleClick = (event, item, index) => {
		if (props.defaultAction && (event.detail === 2)) 
			props.defaultAction(item, index) 
    };
    
	return(
		<Fragment>
			{
			props.choices.map((item, index) => {
				return (
					<Row key={index} className="mb-3">
						<Col md={"auto"}>
							{parse("&#" +(65+index))})
						</Col>
			   			<Col md={"auto"}>
			   				<InputGroup size="sm">
			   					{ (JSON.parse(item.isCorrectAnswer)) ?
			   						<InputGroup.Text className="bg-success border-success text-white"><FontAwesomeIcon icon={faCheck} /></InputGroup.Text>
			   					:
			   					<InputGroup.Radio
            						name="isCorrectAnswer"
            						type={"radio"}
            						id={"isCorrectAnswer"+index}
            						value={index}
            						onChange={() => { props.updateCorrectAnswer(item, index);}}
            						checked={JSON.parse(item.isCorrectAnswer)}
          						/>
          						}
          						<Button variant="warning" onClick={() => { props.updateChoice(item, index);}}><FontAwesomeIcon icon={faEdit} /></Button>
			   					<Button variant="danger" onClick={() => { props.removeChoice(item, index);}}><FontAwesomeIcon icon={faClose} /></Button>
			   				</InputGroup>
          	   			</Col>
			   			<Col onClick={(e) => { handleDoubleClick(e, item, index);}}>{parse(item.choiceText)}</Col>
					</Row>
			)	
		  })}
		</Fragment>
	) 
	
}
	

const ChoicesSection = props => {
	
	const [saveChoiceModalShow, setSaveChoiceModalShow] = useState(false);
	const [deleteChoiceModalShow, setDeleteChoiceModalShow] = useState(false);
	const [selectedChoice, setSelectedChoice] = useState(null);
	
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
		
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const newChoice = () => {
	  setSelectedChoice(null);
	  setSaveChoiceModalShow(true);
    }

 	const updateChoice = (item, index) => {
	  log.debug(item, index);
	  setSelectedChoice(Object.assign({"index": index}, item));
	  setSaveChoiceModalShow(true);
 	}
 	
 	const confirmChoiceRemoval = (item, index) => {
	  setSelectedChoice(Object.assign({"index": index}, item));
	  setDeleteChoiceModalShow(true);
    }
    
    const deleteChoice = (item) => {
		
		setDeleteChoiceModalShow(false);
		
		let choices = props.values.choices;
		
		choices.splice(item.index, 1);
		
		props.setFieldValue("choices", choices);
		props.setFieldError("choices", null);
		
  }
  
  const handleChoiceSaved = (action, item, index) => {
	  setSaveChoiceModalShow(false);
	  
	  let choices = props.values.choices;
	  
	  if (action === "create") {
		  choices.push(item);
	  } else {  
		  choices.splice(index, 1, item);
	  }
		  
	  props.setFieldValue("choices", choices);
	  props.setFieldError("choices", null);
	  
  }
  
  const updateCorrectAnswer = (item, index) => {
	  let choices = props.values.choices.map( (choice, i) => (index === i) ? Object.assign(choice,{"isCorrectAnswer" : true}) : Object.assign(choice,{"isCorrectAnswer" : false}) );
	  
	  props.setFieldValue("choices", choices);
	  props.setFieldError("choices", null);
  }
  
  
	
	
	return(
		<Fragment>
			{(saveChoiceModalShow) && <SaveQuestionChoiceModal
				show={saveChoiceModalShow}
	        	onHide={() => setSaveChoiceModalShow(false)}
				size="xl"
				item={selectedChoice}
				choices={props.values.choices}
				onChoiceSaved={handleChoiceSaved}
		      />
			}
			{(selectedChoice) && <ConfirmationDialogModal
				item={selectedChoice}
				show={deleteChoiceModalShow}
        		onHide={() => setDeleteChoiceModalShow(false)}
				size="lg"
				title={t("questions.delete-choice-confirmation-modal.title")}
				bodyText={t("questions.delete-choice-confirmation-modal.body", {item: selectedChoice})}
				confirmText={t("questions.delete-choice-confirmation-modal.confirm")}
				cancelText={t("questions.delete-choice-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteChoice}
			/>}
			
			<Row>
				<FormGroup as={Col} controlId="formGridChoices" className="mb-1">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.choices.label"}>Choices</Trans> *</FormLabel>
					<fieldset>
						<div className={(props.errors.choices == null) ? "border rounded p-4" : "border border-danger rounded p-4"}>
							<Row className="align-items-center">
							 	<Col>
        							<QuestionChoiceList 
        								choices={props.values.choices} 
        								updateChoice={updateChoice}
        								updateCorrectAnswer={updateCorrectAnswer}
        								removeChoice={confirmChoiceRemoval}
        								defaultAction={updateChoice}
        							/>
        						</Col>
							</Row>
							<Row>
								<Col className="text-center">
									<Button variant="primary" size="sm" onClick={newChoice}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"form.choices.add-button"}>Add Choice</Trans></Button>
							 	</Col>
							</Row>
					</div>
				</fieldset>
				<FormControlErrors block={true} errors={props.errors.choices} />
				</FormGroup>
		   </Row>
		</Fragment>
	) 

}

const ResolutionSection = props => {

	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
		
		return () => { isMounted = false };
		  
	}, []);
	
	const handleResolutionChange = (content) => {
        props.setFieldValue("resolution", content);  
    }
	
	return(
		<Fragment>
			<Row className="mb-3">
				<FormGroup as={Col} controlId="formGridLevel">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.level.label"}>Level</Trans> *</FormLabel>
					<FormControl as="select" name="level" isInvalid={!(props.errors.level == null)} value={props.values.level} onChange={props.onChange} >
		    			<option  value="">{t(props.i18nPrefix+"form.level.options.blank")}</option>
		    			{ QuestionConfig.Levels.map(item =>
		    				<option key={item} className="text-uppercase" value={item}>{t(props.i18nPrefix+"form.level.options."+item)}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.level} />
				</FormGroup>
			
				<FormGroup as={Col} md={4} controlId="formGridEstimatedTime">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.estimatedTime.label"}>Estimated Time</Trans> *</FormLabel>
					<InputGroup>
						<FormControl type={'text'} name="estimatedTime" isInvalid={!(props.errors.estimatedTime == null)} value={props.values.estimatedTime} onChange={props.onChange} />
						<InputGroup.Text><Trans i18nKey={"questions.save.form.estimatedTime.append-text"} /></InputGroup.Text>
						<FormControlErrors errors={props.errors.estimatedTime} />
  					</InputGroup>
				</FormGroup>
				
			</Row>
			
			<Row className="mb-3">
				<FormGroup as={Col} controlId="formGridResolution">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.resolution.label"}>Resolution</Trans></FormLabel>
					<CustomLexicalEditor
  						onChange={handleResolutionChange}
  						content={props.values.resolution}
  						isInvalid={!(props.errors.resolution == null)}
					/>
					<FormControlErrors errors={props.errors.resolution} />
				</FormGroup> 
			</Row>
		</Fragment>
	) 
}

const ImagesSection = props => {

	//const { t } = useTranslation();
	
	const config = useConfig();
	
	const getQuestionImageUrl = (id, attachment) => {
        return API.getQuestionImageUrl(id, attachment);
    }
    
    const getQuestionPublicImageUrl = (id) => {
        return API.getQuestionPublicImageUrl(id);
    }
	
	return(
		<Fragment>
			<MultipleImageUploader 
				i18nPrefix={props.i18nPrefix+"form."}
				//maxImages={0}
				maxFileSize={config.uploadFileSizeLimit}
				images={props.values.images}
				setFieldError={props.setFieldError}
				validateField={props.validateField}
				isInvalid={!(props.errors.images == null)}
				onChange={(images) => { props.setFieldValue("images", images);}}
				getImageUrl={getQuestionImageUrl}
				getPublicImageUrl={getQuestionPublicImageUrl}
			/>
			{!(props.errors.images == null) 
					? <div className="text-left invalid-feedback" style={{display: "block"}}><Trans i18nKey={props.errors.images} /></div>
				: null
			}	
		</Fragment>
	) 

}




const QuestionFormFields = { GeneralSection, QuestionSection, ChoicesSection, ResolutionSection, ImagesSection };
		
export default QuestionFormFields;
