import * as Yup from "yup";
import moment from "moment";
import ImportConfig from "../config/ImportConfig";
import { isArrayWithLength } from "../helpers/commons";

Yup.addMethod(Yup.array, "hasOneCorrectAnswer", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("hasOneCorrectAnswer", message, function(value) {
        const { path, createError } = this;
		
		if (!isArrayWithLength(value)) return true;
		
		console.log(value);
		let correctAnswers = value.filter(item => JSON.parse(item.isCorrectAnswer));
		
    	return correctAnswers.length === 1
    			|| createError({ path, message: message }); 
    
    });
});

const Grades = [
	"FUNDAMENTAL",
	"ENSINO_MEDIO_1",
	"ENSINO_MEDIO_2",
	"ENSINO_MEDIO_3"
]

/*const Subjects = [
	 "MATH",
	 "HISTORY",
	 "GEOGRAPHY",
	 "BIOLOGY",
	 "CHEMISTRY",
	 "PHYSICS",
	 "PORTUGUESE",
	 "ENGLISH"
]*/


const Levels = [
	"LEVEL_0", 
	"LEVEL_1", 
	"LEVEL_2", 
	"LEVEL_3", 
]

const Types = [
	"MULTIPLE_CHOICE"
]

const GeneralFormValidationRules = {
	subjectId: Yup.string()
		.required()
   		.default(() => ""),
	topicId: Yup.string()
		.required()
   		.default(""),
   	type: Yup.string()
		.oneOf([""].concat(Types), 'form.validation.string.enum')
		.required()
   		.default("MULTIPLE_CHOICE"),
   	grade: Yup.string()
		.oneOf([""].concat(Grades), 'form.validation.string.enum')
		.required()
   		.default(() => ""),
	sourceId: Yup.string()
		.required()
   		.default(() => ""),
   	year: Yup.string()
		.max(4, 'questions.save.form.year.validation.maximumlength')
		.required()
   		.default(() => "")
}

const QuestionTextFormValidationRules = {
	questionText: Yup.string()
		.max(50000, 'questions.save.form.questionText.maximumlength')
		.required()
		.default(() => ""),
}

const ChoicesFormValidationRules = {
	choices: Yup.array()
		.min(1, 'questions.save.form.choices.validation.required')
		.hasOneCorrectAnswer({ message: 'questions.save.form.choices.validation.has-one-correct-answer'})
		.default(() => []),
}

const ResolutionFormValidationRules = {
	level: Yup.string()
		.oneOf([""].concat(Levels), 'form.validation.string.enum')
		.required()
   		.default(""),
	estimatedTime: Yup.string()
		.required()
   		.default(() => ""),
	resolution: Yup.string()
		.max(50000, 'questions.save.form.resolution.maximumlength')
		.default(() => ""),
}

const ImagesFormValidationRules = {
	images: Yup.array().default(() => [])
}

const QuestionChoiceFormValidationRules = {
   	choiceText: Yup.string()
   		.required()
   		.max(10000, 'questions.save-choice-modal.form.choiceText.validation.maximumlength')
   		.default(""),		
   	isCorrectAnswer: Yup.boolean()
    	.typeError('form.validation.boolean')
    	.default(() => false),
   		
}

const ExportHeaders =[
	"id", "grade", "subject", "topic", "level", "type", "source", "year", "questionText", "choices", "estimatedTime", "resolution", "images", "whenModified", "whenCreated"
]

const ImportHeaders =[
	"id", "grade", "subject", "topic", "level", "type", "source", "year", "questionText", "choices", "estimatedTime", "resolution", "images"
]

const ImportActions = ImportConfig.Actions;
const ImportDelimiterOptions = ImportConfig.DelimiterOptions;
const ImportFirstRowHeaderOptions = ImportConfig.FirstRowHeaderOptions;
const ImportColumnMappingStrategyOptions = ImportConfig.ColumnMappingStrategyOptions;

const ImportFormValidationRules = {
	   action: Yup.string()
		.oneOf([""].concat(ImportConfig.Actions), 'form.validation.string.enum')
		.required()
   		.default(() => "CREATE"),
	   data : Yup.array().of(Yup.object().shape(Object.assign({}, GeneralFormValidationRules, QuestionTextFormValidationRules, ResolutionFormValidationRules )))
		.max(255, 'questions.import.form.data.validation.maximum-size' )
		.default(() => []),
}

const FilterFormValidationRules = {
	filter: Yup.string()
		.test(
        	'empty-or-3-characters-check',
        	'questions.list.form.filter.validation.minimumlength',
        	filter => !filter || filter.length >= 3,
    	)
		.default(() => ""),
	filteredColumn: Yup.string()
		.default(() => "text"),
	subjectId: Yup.string()
   		.default(() => ""),
   	topicId: Yup.string()
   		.default(() => "")
	
}

const AdvancedFilterFormFields = {
	level: Yup.string()
		.oneOf([""].concat(Levels), 'form.validation.string.enum')
   		.default(() => ""),
   	type: Yup.string()
		.oneOf([""].concat(Types), 'form.validation.string.enum')
   		.default(() => ""),
   	sourceId: Yup.string()
		.default(() => "text"),
	grade: Yup.string()
		.oneOf([""].concat(Object.values(Grades)), 'form.validation.string.enum')
   		.default(() => ""),
   	year: Yup.string()
		.default(() => "text"),
}

const QuestionConfig = { Grades, Levels, Types, GeneralFormValidationRules, QuestionTextFormValidationRules, 
		ChoicesFormValidationRules, ResolutionFormValidationRules, ImagesFormValidationRules, QuestionChoiceFormValidationRules,
		ImportHeaders, ExportHeaders, ImportFormValidationRules,
		ImportActions, ImportDelimiterOptions, ImportFirstRowHeaderOptions, ImportColumnMappingStrategyOptions,
		FilterFormValidationRules, AdvancedFilterFormFields
		
};

export default QuestionConfig;