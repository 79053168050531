import React, {useState} from 'react';
import { Trans } from 'react-i18next';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';

import Switch from "../../components/Switch";

import { ErrorBoundary } from "react-error-boundary"

import KatexRenderer from "../lexical/KatexRenderer"

const InsertEquationModal = props => {
	
	const [equation, setEquation] = useState("");
  	const [inline, setInline] = useState(true);
  
	const handleClick = () => {
    	if (props.onEquationConfirm)
    		props.onEquationConfirm(equation, inline) 
  	}
  
	return (
		<Modal
		    show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-insert-equation-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-insert-equation-modal">
  					{(props.title)
  						? props.title
  						: <Trans i18nKey="components.insert-equation-modal.title">Insert Equation Title</Trans>
  					}
  				</Modal.Title>
  			</Modal.Header>
  			<Modal.Body className="pt-0 pb-0">
  				<Container className="p-2">
  					<Row className="mb-3">
						<FormGroup as={Col} controlId="formGridEquation">
							<FormLabel><Trans i18nKey={"components.insert-equation-modal.equation.label"}>Equation</Trans></FormLabel>
							<FormControl as="textarea" name="equation" value={equation} onChange={(e) => { setEquation(e.target.value)}} />
						</FormGroup>
						<FormGroup as={Col} md={"auto"} controlId="formGridInline">
							<FormLabel><Trans i18nKey={"components.insert-equation-modal.inline.label"}>Inline</Trans></FormLabel>
							<Switch 
	    						name="inline"
	    						value={inline}
	    						checked={inline}
	    						onChange={() => { setInline(!inline);}}
	    					/>
						</FormGroup>
					</Row>
					<Row className="mb-1">
						<FormGroup as={Col} controlId="formGridPreviewLabel">
							<FormLabel><Trans i18nKey={"components.insert-equation-modal.preview.label"}>Preview</Trans></FormLabel>
						</FormGroup>
					</Row>
					<Row className="mb-3">
						<FormGroup as={Col} controlId="formGridPreviewRenderer">
							<ErrorBoundary onError={e => props.editor._onError(e)} fallback={null}>
          						<KatexRenderer
           							equation={equation}
            						inline={false}
            						onDoubleClick={() => null}
          						/>
        					</ErrorBoundary>
						</FormGroup>
					</Row>
				</Container>
		  	</Modal.Body>
          	<Modal.Footer>			      		
          		<Button variant={props.variant} onClick={handleClick}> 
          			{(props.confirmText)
  						? props.confirmText
  						: <Trans i18nKey="components.insert-equation-modal.confirm">OK</Trans>
  					}
          		</Button>
          		<Button variant="secondary" onClick={props.onHide}>
          			{(props.cancelText)
  						? props.cancelText
  						: <Trans i18nKey="components.insert-equation-modal.cancel">Cancel</Trans>
  					}
				</Button>	
          	</Modal.Footer>
		</Modal>
	);
}

export default InsertEquationModal;
