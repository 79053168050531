import React, { useState, useEffect} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter} from '@fortawesome/free-solid-svg-icons'

import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

import FormControlErrors from "../components/FormControlErrors";

import QuestionSourceInputSearch from "../components/QuestionSourceInputSearch";

import * as Yup from "yup";

import QuestionConfig from "../config/QuestionConfig";

import API from "../services/backend-api";


const displayFiltersButton = {
    position: "absolute", 
	right: "0.75rem"
}

const validationSchema = Yup.object().shape(QuestionConfig.FilterFormValidationRules);

const QuestionFilterFormFields = props => {
	
	const [subjects, setSubjects] = useState([]);
	const [subjectTopics, setSubjectTopics] = useState([]);
	const [displayMoreFilters, setDisplayMoreFilters] = useState(false);
		
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
		
		API.findSubjects({}).then(response => {
			if (isMounted) setSubjects(response.list);
		}).catch(error => {			
			log.error("Error Loading Subjects: ", error.message);
		 	props.onError(error);
		});
			
		
		return () => { isMounted = false };
		  
	}, []);
	

	const toogleDisplayFilters = () => {
		
		let currentDisplayMode = displayMoreFilters;
		
		if (displayMoreFilters) {
			
			let defaultValues = validationSchema.default();
			
			Object.keys(QuestionConfig.AdvancedFilterFormFields)
			.forEach((key) => {
				props.setFieldValue(key, defaultValues[key]);
			});
			
			props.submitForm();
		}
		
		setDisplayMoreFilters(!currentDisplayMode);
		
    } 

	const handleSourceChange = (source) => {
		
		if (!source) {
			props.setFieldValue("sourceId", "");
        	return;
        } 

		props.setFieldValue("sourceId", source.id);
    }

	const getSubjectTopics = (subjectId) => {
    	API.findSubjectTopics(subjectId, {})
			.then(response => {
					setSubjectTopics(response.list); 					
			})
			.catch(error => { 
				log.error("Error Loading Subject Topics: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }
	
	const handleSubjectChange = (e) => {
        
        props.setFieldValue("subjectId", e.target.value);
        
        if (!e.target.value) {
        	setSubjectTopics([]);
        	props.setFieldValue("topicId", "");
        	return;
        }
        
        //Get Subject Topics
        getSubjectTopics(e.target.value);
        
        //Reset topic
        props.setFieldValue("topicId", "");
    }
	
	return (
		<fieldset className="position-relative">
			<div className="position-absolute top-0 end-0">  					   
				<Button variant="link" onClick={toogleDisplayFilters} className="float-end"><FontAwesomeIcon icon={faFilter} /> {(displayMoreFilters)
					? <Trans i18nKey={props.i18nPrefix+"display-less-filters"}>Display - Filters</Trans>
					: <Trans i18nKey={props.i18nPrefix+"display-more-filters"}>Display + Filters</Trans>
					}	
				</Button>
			</div>
			<div className={"border rounded p-4"}>
			
				<FormGroup as={Row} controlId="formGridSubject" className="mb-3">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"subjectId.label"}>Subject/Topic</Trans>
    				</FormLabel>
					<Col sm="3">
						<FormControl as="select" name="subjectId" isInvalid={!(props.errors.subjectId == null)} value={props.values.subjectId} onChange={handleSubjectChange} >
		    				<option  value="">{t(props.i18nPrefix+"subjectId.blank-option")}</option>
		    				{ subjects.map(item =>
		    					<option key={item.id} value={item.id+""}>{item.name}</option>
		    			    )}
		    			</FormControl>
						<FormControlErrors errors={props.errors.subjectId} />
					</Col>
					<Col sm="4">
						<FormControl as="select" name="topicId" disabled={!(props.values.subjectId)} isInvalid={!(props.errors.topicId == null)} value={props.values.topicId} onChange={props.onChange} >
		    				<option  value="">{t(props.i18nPrefix+"topicId.blank-option")}</option>
		    				{ subjectTopics.map(item =>
		    					<option key={item.id} value={item.id}>{item.name}</option>
		    			)}
		    			</FormControl>
						<FormControlErrors errors={props.errors.topicId} />
					</Col>	
				</FormGroup>		
				
				{ displayMoreFilters &&
				 	<FormGroup as={Row} controlId="formGridSource" className="mb-3">
						<FormLabel column sm="2">
							<Trans i18nKey={props.i18nPrefix+"sourceId.label"}>Source</Trans>
						</FormLabel>
						<Col sm="7">
							<QuestionSourceInputSearch 
				    			i18nPrefix={props.i18nPrefix}
				    			onError={props.onError}
				    			isInvalid={!(props.errors.sourceId == null)}
				    			onChange={handleSourceChange}
								sourceId={props.values.sourceId}
				    		/>
							<FormControlErrors block errors={props.errors.sourceId} />
						</Col>
					</FormGroup>
				}
		
				
						
						
				{ displayMoreFilters &&
				<FormGroup as={Row} controlId="formGridLevel" className="mb-3">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"level.label"}>Level</Trans>
    				</FormLabel>
					<Col sm="7">
						<FormControl as="select" name="level" isInvalid={!(props.errors.level == null)} value={props.values.level} onChange={props.onChange} >
		    				<option  value="">{t(props.i18nPrefix+"level.options.blank")}</option>
		    				{ QuestionConfig.Levels.map(item =>
		    					<option key={item} className="text-uppercase" value={item}>{t(props.i18nPrefix+"level.options."+item)}</option>
		    				)}
		    		    </FormControl>
						<FormControlErrors errors={props.errors.level} />
   					</Col>
				</FormGroup>
				}
						
						
				<FormGroup as={Row} controlId="formGridQuestionText" className="mb-3">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"filter.label"}>Question Text</Trans>
    				</FormLabel>
					<Col sm="7">
						<FormControl type={'search'} name="filter" isInvalid={!(props.errors.filter == null)} value={props.values.filter} onChange={props.onChange} placeholder={t(props.i18nPrefix+"filter.placeholder")} />
						<FormControlErrors errors={props.errors.filter} />
					</Col>
					<Col sm="2">
						<Button variant="success" type="submit" disabled={props.isSubmitting} onClick={() => {}}> 
							{props.isSubmitting ? <Trans i18nKey={props.i18nPrefix+"submitting"}>Submit</Trans> : <Trans i18nKey={props.i18nPrefix+"submit"}>Submit</Trans>} 
						</Button>
					</Col>
				</FormGroup>
				
			</div>
		</fieldset>
	)

}

export default QuestionFilterFormFields;
