import React, { Fragment, useState, useEffect, useRef} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {useDropzone} from 'react-dropzone';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faDownload, faLink, faCopy } from '@fortawesome/free-solid-svg-icons'

//import {filesize} from 'filesize';

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import ConfirmationDialogModal from "../components/ConfirmationDialogModal";
import ZoomImageModal from "../components/ZoomImageModal";

import {CopyToClipboard} from 'react-copy-to-clipboard';

import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";

import {niceBytes} from  "../helpers/commons";

import '../styles/MultipleImageUploader.css';

const thumb = {
	position: "relative",
	display: 'inline-flex',
	//borderRadius: 2,
	//border: "1px solid #eaeaea",
	marginBottom: 8,
	marginRight: 8,
	width: 160,
	height: 160,
	padding: 4,
	boxSizing: 'border-box'
};


const thumbInner = {
	//display: 'flex',
	minWidth: 0,
	overflow: 'hidden'
};

const img = {
	display: 'block',
	width: '100%',//width: 'auto',
	height: '100%',
	cursor: "zoom-in",
	objectFit: "fill"
};

const thumbLinkButton = {
	position: "absolute",
	left: "50%", /* Moves button to the middle horizontally */
  	transform: "translate(-50%, 0)", /* Centers the button */
	bottom: 10,
	opacity: 0.9,
	border: 0,
	borderRadius: ".325em",
};

const thumbRemoveButton = {
	position: "absolute",
	right: 10,
	bottom: 10,
	opacity: 0.9,
	border: 0,
	borderRadius: ".325em",
};

const thumbDownloadButton = {
	position: "absolute",
	left: 10,
	bottom: 10,
	opacity: 0.9,
	border: 0,
	borderRadius: ".325em",
};


const MultipleImageUploader = props => {

	const [images, setImages] = useState([]);
	const [removeImageConfirmationModalShow, setRemoveImageConfirmationModalShow] = useState(false);
	const [selectedImageIndex, setSelectedImageIndex] = useState(null);
	const [zoomImageModalShow, setZoomImageModalShow] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
	const target = useRef(null);
	
	const { t } = useTranslation();
	
	const auth = useAuth();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (isMounted) {
			
			let currentImages = props.images.map(item => {
			
				let image = {};
				
				if (item.id) {
					image.id = item.id;
					image.preview = props.getImageUrl(item.id);
					image.systemFileName = item.systemFileName;
				} else if (item.clonedObjectId) {
		    		image.preview = props.getImageUrl(item.clonedObjectId);
		    		image.systemFileName = item.systemFileName;
				} else if (item.uploadedImage) {
					image.uploadedImage = item.uploadedImage;
		    		image.preview = URL.createObjectURL(item.uploadedImage);
				}
				
				return image;
			
			});
			
			setImages(currentImages);
		}
				 
		 return () => { isMounted = false };
		  
	}, []);
	
	const renderTooltip = (text) => (
   	 	<Tooltip id="button-tooltip" >
      		<Trans i18nKey={text} />
    	</Tooltip>
  	);

	const handleImageUrlCopied = () => {
		setShowCopiedTooltip(true); 
		setTimeout(() => {setShowCopiedTooltip(false);}, 3000);		
	} 
	
	const {getRootProps, getInputProps} = useDropzone({
		accept: {
  			'image/png': ['.png'], 
  			'image/gif': ['.gif'], 
  			'image/jpeg': ['.jpg', '.jpeg'] 
		},
		maxFiles: (props.maxImages) ? props.maxImages - images.length : 0,
		//disabled: (images.length >= props.maxImages),
		maxSize: props.maxFileSize, 
	    onDrop: (acceptedFiles, fileRejections) => {
	    	let currentImages = images;
	    	
	    	props.validateField("images").then((e) => {
	    	
	    		fileRejections.forEach(({ file, errors  }) => { 
   					errors.forEach(e => {
   						props.setFieldError("images", t(props.i18nPrefix+"multiple-image-uploader.validation."+e.code, {file: file, maxFileSize: niceBytes(props.maxFileSize)}));
   					});

   				});
	    		
	    		acceptedFiles.forEach( file => { 	
	    			//Check for image size
	    			if (file.size > props.maxFileSize) { 
	    				props.setFieldError("images", t(props.i18nPrefix+"multiple-image-uploader.validation.max-file-size", {fileName: file.path, maxFileSize: niceBytes(props.maxFileSize) }));
	    			} else {
	    				currentImages.push({ 
		    				uploadedImage: file,
		    				preview : URL.createObjectURL(file),
			    		});
			    	}
	    		});
	    		
	    		setImages(currentImages);
	    	
	    		props.onChange(images);
									
			});
	    	
	    }
	  });
	
	
	const removeImage = (index) => {
		let currentImages = images;
		
		currentImages.splice(index,1);
		
		setImages(currentImages);
		
		props.onChange(images);
		
		setRemoveImageConfirmationModalShow(false);
	}
	
    return (
        <Container>
        	{(selectedImageIndex !== null) && (
        		<ConfirmationDialogModal
        			item={selectedImageIndex}
    				show={removeImageConfirmationModalShow}
    				onHide={() => setRemoveImageConfirmationModalShow(false)}
    				title={t(props.i18nPrefix+"multiple-image-uploader.remove-image-confirmation-modal.title")}
					bodyText={t(props.i18nPrefix+"multiple-image-uploader.remove-image-confirmation-modal.body")}
					confirmText={t(props.i18nPrefix+"multiple-image-uploader.remove-image-confirmation-modal.confirm")}
					cancelText={t(props.i18nPrefix+"multiple-image-uploader.remove-image-confirmation-modal.cancel")}
					variant="danger"
					onConfirmation={removeImage}
        		/>
        	)}
        	{(selectedImage) && (
        		<ZoomImageModal
        			item={selectedImage}
					show={zoomImageModalShow}
					size="lg"
    				onHide={() => setZoomImageModalShow(false)}
        		/>
        	)}
    		<Row className="mb-3">
				<Col>
					<div {...getRootProps({className: 'dropzone' + (props.isInvalid ? ' border-danger': '') + ((images.length >= props.maxImages) ? ' disabled' : '')})}>
	        			<input {...getInputProps()} />
	        			{(images.length >= props.maxImages)
	        				? (
	        				 	<p className="mb-0"><Trans i18nKey={props.i18nPrefix+"multiple-image-uploader.max-images-reached"} values={{maxImages: props.maxImages}} /></p>
	        				) : (
	        					<Fragment>
	        					<p><Trans i18nKey={props.i18nPrefix+"multiple-image-uploader.max-images-disclaimer"} values={{maxImages: props.maxImages, maxFileSize: niceBytes(props.maxFileSize)}} /></p>
	        					<p className="mb-0"><Trans i18nKey={props.i18nPrefix+"multiple-image-uploader.drag-and-drop-instructions"} /></p>
	        				 </Fragment>
						)}
	        	</div>
	    		</Col>
	    	</Row>	
	    	
	    	<Row className="mb-3"  >
	    		<Col>
	    		{ images.map((image, index) => (
					<div key={index}  style={thumb} className="img-thumbnail">
				      	<div style={thumbInner} className="w-100">
				      		<Image src={image.preview} style={img} onClick={() => { setSelectedImage(image); setZoomImageModalShow(true); } } />
				      						      		
							{(image.id) && (
								<Button variant="success" style={thumbDownloadButton} href={props.getImageUrl(image.id, true)}>
				      				<FontAwesomeIcon icon={faDownload} />
				      			</Button>
							)}
							
							{(image.systemFileName) && (
								<OverlayTrigger
									show={showCopiedTooltip}
									overlay={renderTooltip(props.i18nPrefix+"multiple-image-uploader.tooltip.copied")}
    							>
								<CopyToClipboard 
									text={props.getPublicImageUrl(image.systemFileName)} 
									onCopy={handleImageUrlCopied}
								>
								<Button variant="secondary" style={thumbLinkButton} ref={target}>
				      				<FontAwesomeIcon icon={faLink} />
				      			</Button>
				      											</CopyToClipboard>
							</OverlayTrigger>
				      		)}
							
							<Button variant="danger" style={thumbRemoveButton} onClick={() => { setSelectedImageIndex(index); setRemoveImageConfirmationModalShow(true); } }>
				      			<FontAwesomeIcon icon={faTimesCircle} />
				      		</Button>

				        </div>
				    </div>
				    
	    		))}
	    		</Col>
	    	</Row>
        </Container>
    )
}
	        			
/*MultipleImageUploader.propTypes = {
	maxImages: PropTypes.number,
	images: PropTypes.array
}

MultipleImageUploader.defaultProps = {
	maxImages: 0,
	images: []
};*/

export default MultipleImageUploader;