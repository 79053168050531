import React,  { useState, useEffect } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import Loader from "../components/Loader";
import PagedList from "../components/PagedList";
import SingleFilterForm from "../components/SingleFilterForm";

import UserConfig from "../config/UserConfig";

import { jsonToCSV } from "react-papaparse";

import API from "../services/backend-api";

const Users = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedUsers, setPagedUsers] = useState(null);
	
	const location = useLocation();
	
	const { t } = useTranslation();
	
	let navigate = useNavigate();
	
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (isMounted && location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		pageUsers({})
			.catch(error => { 
				log.error("Error Loading Initial Users List: ", error.message);
				context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);
	
  const updateUser = (user) => {
	  navigate("/users/update", { state: { item: user} });
  }
  
  const addUser = () => {
	  navigate("/users/create");
  }
  
  const updateUserPassword = (user) => {
	  navigate("/users/update-password", { state: { item: user}});
  }
    
  const updatePage = (values) => {
	  pageUsers(values)
		.catch(error => { 
			log.error("Error Paginating Users List: ", error.message);
			context.onError(error);
		})
		
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageUsers(values)
		.catch(error => { 
			log.error("Error Sorting Users List: ", error.message);
			context.onError(error);
		})
		
  }
  
  const displayInactive = (item) => { 
	return (item.active) ? "" : "inactive-row"	
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
  const exportUsers = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		let exportFilters = Object.assign({}, mergeQueryParams(pagedUsers, values));
		delete exportFilters.pageIndex;
		delete exportFilters.pageSize;
		
		API.findUsers(exportFilters).then(response => {

			let columns = UserConfig.ExportHeaders;
			
			//Transform data before parse to csv format
			let data = response.list.map(r => {
				
				let row = Object.assign({}, r);
				
				row.active = t("users.list.export.enums.active."+row.active);
				//row.xactTypeCode = t("users-transactions.list.export.enums.xactTypeCode."+row.xactTypeCode);
				
				//row.amount = formatNumber(r.amount, config.preferredLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2});
				
				return row;
			});
			
			let rows = jsonToCSV(data, {
  					quotes: true, 
  					header: false,
  					columns: columns
			});

			let headers = jsonToCSV({ fields: columns.map(c => t("users.list.export.columns."+c)), data: [] }, {quotes: true});

			let result = headers + rows;
			
			resolve(result);
			
		}).catch(error => {			
			reject(error);
		});
	});

  
  const pageUsers = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
		context.onError(null);
		
		API.pageUsers(mergeQueryParams(pagedUsers, values)).then(response => {
			setPagedUsers(response);
			resolve(response);
		}).catch(error => { 	
			reject(error);
		});
	});
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedUsers) 
		return null;
		
	return (
		<PagedList
			i18nPrefix="users.list."
			page={pagedUsers}
			pageSizeOptions={[10,25,50,100]}
			filterFormComponent={SingleFilterForm}
			filterParameters={{ filter: pagedUsers.filter, filteredColumn: pagedUsers.filteredColumn }}
			onFilter={pageUsers}
			onExport={exportUsers}
			onError={context.onError}
			onSort={sortDataTable}
			onAdd={addUser}
			onPage={updatePage}
			dataTableColumns={["firstName", "lastName", "displayName", "email", "lastLogin"]} 
			dataTableSortableColumns={["displayName", "firstName", "lastName", "email", "lastLogin"]}
			dataTableCustomDisplayColumns={[["lastLogin", "ZonedDateTime"]]}
			dataTableActions={[updateUser, updateUserPassword]}
			dataTableDefaultAction={updateUser}
			dataTableCustomRowStyle={displayInactive}		
		/>
		
	);
}

export default Users;
